<template>
  <v-row
    v-if="recoveringPassword"
    no-gutters
    class="d-flex justify-center align-center"
  >
    <v-col cols="12" class="d-flex flex-column align-center">
      <div class="background-help">
        <font-awesome-icon class="help" icon="check" />
      </div>
      <div class="text-center">
        {{ $t('login.check_email') }}
      </div>
      <div
        class="text-center contact"
        v-html="$t('login.check_email_text')"
      ></div>
    </v-col>
  </v-row>
  <v-row v-else no-gutters class="d-flex justify-center align-center">
    <v-col cols="12" no-gutters>
      <v-row no-gutters class="recover-password">
        {{ $t('login.recover_password') }}
      </v-row>
      <v-row no-gutters class="options-label pt-4">
        {{ $t('login.email') }}
      </v-row>
      <v-text-field
        v-model="loginFormEmail"
        :placeholder="$t('login.digit_email')"
        solo
        outlined
        dense
        hide-details
        color="#39af49"
      >
        <template #append>
          <font-awesome-icon class="icon-outlined" icon="envelope" />
        </template>
      </v-text-field>
      <v-row no-gutters class="access-account">
        <v-btn
          class="text-none platform-access-button"
          width="100%"
          :loading="isLoading"
          @click="recoverPasswordLogin"
        >
          <span class="platform-access">
            {{ $t('login.send_email') }}
          </span>
          <font-awesome-icon class="icon-access" icon="paper-plane" />
        </v-btn>
      </v-row>
      <v-row no-gutters class="pt-6 align-center justify-center" offset-md="3">
        <a class="text-link text-none" @click.prevent="backToLogin">
          {{ $t('login.back_to_login') }}
        </a>
        <font-awesome-icon class="icon-link" icon="arrow-right" />
      </v-row>
      <v-row no-gutters class="error-message">
        <alert-login-error />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AlertLoginError from '@/modules/auth/components/AlertLoginError.vue'
import { mapActions } from 'vuex'

export default {
  name: 'RecoverPasswordForm',

  components: {
    AlertLoginError,
  },

  props: {
    email: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      isLoading: false,
      recoveringPassword: false,
    }
  },

  computed: {
    loginFormEmail: {
      get() {
        return this.email
      },
      set(value) {
        this.$emit('change-email', value)
      },
    },
  },

  methods: {
    ...mapActions('apiManager', ['resetApiErrorMessage']),
    backToLogin() {
      this.resetApiErrorMessage()
      this.$emit('recover-password', false)
    },
    async recoverPasswordLogin() {
      this.isLoading = true
      try {
        const response = await this.$api.auth.recoverPassword(this.email)
        if (response.status === 200) {
          this.recoveringPassword = true
          setTimeout(() => {
            this.recoveringPassword = false
          }, 5000)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.background-help {
  background-color: #e3f8e1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px !important;
  height: 45px !important;
}
.contact {
  color: #5b6459;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.recover-password {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.options-label {
  color: #253521;
  font-family: 'Rubik';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: -3px;
}
.icon-outlined {
  color: #788476;
  font-family: 'Font Awesome 5 Free';
  font-size: 10px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.access-account {
  padding-top: 27px;
}
.platform-access-button {
  background-color: #39af49 !important;
}
.platform-access {
  color: #fff;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
}
.icon-access {
  color: #fff !important;
  font-size: 10px;
  margin-left: 5px;
}
.text-link {
  color: #788476;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-decoration-line: underline;
}
.icon-link {
  color: #5b6459;
  font-size: 10px;
  margin-left: 5px;
}
.error-message {
  padding-top: 28px;
  height: 72px;
}
</style>
